import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from "./Pages/home";
import Cv from "./Pages/cv";
import Contact from "./Pages/contact";
import Nopage from "./Pages/nopage";

import React from "react";
import ReactDOM from "react-dom";

function App() {
  return (
    <div>
      <BrowserRouter forceRefresh>
        <Routes>
          <Route path="/" exact={true} element={<Home />} />
          <Route path="/home" exact={true} element={<Home />} />
          <Route index exact={true} element={<Home />} />
          <Route path="/cv" exact={true} element={<Cv />} />
          <Route path="/contactar" exact={true} element={<Contact />} />
          <Route path="*" element={<Nopage />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
