import { Component } from "react";
import Header from "../Modulos/header";
import Footer2 from "../Modulos/footer2";

class cv extends Component {
  render() {
    return (
      <div>
        <div className="preloader">
          <div className="centrize full-width">
            <div className="vertical-center">
              <div className="spinner">
                <div className="double-bounce1"></div>
                <div className="double-bounce2"></div>
              </div>
            </div>
          </div>
        </div>

        <div className="container">
          <div className="cursor-follower"></div>

          {/* Header */}
          <Header></Header>

          <div className="wrapper">
            <div className="section started section-title" id="section-started">
              <div className="video-bg">
                <div className="video-bg-mask"></div>
                <div className="video-bg-texture" id="grained_container"></div>
              </div>

              <div className="centrize full-width">
                <div className="vertical-center">
                  <div className="started-content">
                    <h1 className="h-title">Resume</h1>
                    <div className="h-subtitles">
                      <div className="h-subtitle typing-bread">
                        <p className="breadcrumbs">
                          <a href="index.html">Home</a> / Resume
                        </p>
                      </div>
                      <span className="typed-bread"></span>
                    </div>
                  </div>
                </div>
              </div>

              <a href="#" className="mouse_btn" style="display: none;">
                <span className="icon fas fa-chevron-down"></span>
              </a>
            </div>

            <div className="section about" id="section-about">
              <div className="content content-box">
                <div className="image">
                  <img src="images/man2.jpg" alt="" />
                </div>

                <div className="desc">
                  <p>
                    Hello! I’m Daniel Curry. Web designer from USA, California,
                    San Francisco. I have rich experience in web site design and
                    building, also I am good at wordpress. I love to talk with
                    you about our unique.
                  </p>
                  <div className="info-list">
                    <ul>
                      <li>
                        <strong>Age:</strong> 24
                      </li>
                      <li>
                        <strong>Residence:</strong> USA
                      </li>
                      <li>
                        <strong>Freelance:</strong> Available
                      </li>
                      <li>
                        <strong>Address:</strong> San Francisco
                      </li>
                      <li>
                        <strong>Phone:</strong> +1 256 254 84 56
                      </li>
                      <li>
                        <strong>E-mail:</strong> alejandroa@gmail.com
                      </li>
                    </ul>
                  </div>
                  <div className="bts">
                    <a href="#" className="btn hover-animated">
                      <span className="circle"></span>
                      <span className="lnk">Download CV</span>
                    </a>
                  </div>
                </div>

                <div className="clear"></div>
              </div>
            </div>

            <div className="section service" id="section-services">
              <div className="content">
                <div className="title">
                  <div className="title_inner">Services</div>
                </div>

                <div className="service-items">
                  <div className="service-col">
                    <div className="service-item content-box">
                      <div className="icon">
                        <span className="fas fa-code"></span>
                      </div>
                      <div className="name">Web Development</div>
                      <div className="text">
                        Modern and mobile-ready website that will help you reach
                        all of your marketing.
                      </div>
                    </div>
                  </div>

                  <div className="service-col">
                    <div className="service-item content-box">
                      <div className="icon">
                        <span className="fas fa-music"></span>
                      </div>
                      <div className="name">Music Writing</div>
                      <div className="text">
                        Music copying, writing, creating, transcription,
                        arranging and services.
                      </div>
                    </div>
                  </div>

                  <div className="service-col">
                    <div className="service-item content-box">
                      <div className="icon">
                        <span className="fas fa-ad"></span>
                      </div>
                      <div className="name">Advetising</div>
                      <div className="text">
                        Advertising services include television, radio, print,
                        mail, and web apps.
                      </div>
                    </div>
                  </div>

                  <div className="service-col">
                    <div className="service-item content-box">
                      <div className="icon">
                        <span className="fas fa-gamepad"></span>
                      </div>
                      <div className="name">Game Development</div>
                      <div className="text">
                        Developing memorable and unique mobile android, ios and
                        video games.
                      </div>
                    </div>
                  </div>

                  <div className="service-col">
                    <div className="service-item content-box">
                      <div className="icon">
                        <span className="fas fa-camera"></span>
                      </div>
                      <div className="name">Photography</div>
                      <div className="text">
                        Our in-house photography services team made up of
                        professional photographers.
                      </div>
                    </div>
                  </div>

                  <div className="service-col">
                    <div className="service-item content-box">
                      <div className="icon">
                        <span className="fas fa-mobile-alt"></span>
                      </div>
                      <div className="name">Android Application</div>
                      <div className="text">
                        Games, playing music, handle network transactions,
                        interacting content etc.
                      </div>
                    </div>
                  </div>
                </div>

                <div className="clear"></div>
              </div>
            </div>

            <div className="section pricing" id="section-pricing">
              <div className="content">
                <div className="title">
                  <div className="title_inner">Pricing Table</div>
                </div>

                <div className="pricing-items">
                  <div className="pricing-col">
                    <div className="pricing-item content-box">
                      <div className="icon">
                        <span className="fas fa-star"></span>
                      </div>
                      <div className="name">Basic</div>
                      <div className="amount">
                        <span className="number">
                          <span className="dollar">$</span>
                          <span>39</span>
                          <span className="period">hour</span>
                        </span>
                      </div>
                      <div className="feature-list">
                        <ul>
                          <li>Web Development</li>
                          <li>Advetising</li>
                          <li>Game Development</li>
                          <li className="disable">Music Writing</li>
                          <li className="disable">Photography</li>
                        </ul>
                      </div>
                      <div className="bts">
                        <a href="#" className="btn hover-animated">
                          <span className="circle"></span>
                          <span className="lnk">Buy Now</span>
                        </a>
                      </div>
                    </div>
                  </div>

                  <div className="pricing-col">
                    <div className="pricing-item content-box">
                      <div className="icon">
                        <span className="fas fa-rocket"></span>
                      </div>
                      <div className="name">Premium</div>
                      <div className="amount">
                        <span className="number">
                          <span className="dollar">$</span>
                          <span>59</span>
                          <span className="period">hour</span>
                        </span>
                      </div>
                      <div className="feature-list">
                        <ul>
                          <li>Web Development</li>
                          <li>Advetising</li>
                          <li>Game Development</li>
                          <li>Music Writing</li>
                          <li>
                            Photography <strong>new</strong>
                          </li>
                        </ul>
                      </div>
                      <div className="bts">
                        <a href="#" className="btn hover-animated">
                          <span className="circle"></span>
                          <span className="lnk">Buy Now</span>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="section resume" id="section-history">
              <div className="content">
                <div className="cols">
                  <div className="col col-md">
                    <div className="title">
                      <div className="title_inner">Experience</div>
                    </div>

                    <div className="resume-items">
                      <div className="resume-item content-box active">
                        <div className="date">2013 - Present</div>
                        <div className="name">Art Director - Facebook Inc.</div>
                        <div className="text">
                          Collaborate with creative and development teams on the
                          execution of ideas.
                        </div>
                      </div>

                      <div className="resume-item content-box">
                        <div className="date">2011 - 2012</div>
                        <div className="name">
                          Front-End Developer - Google Inc.
                        </div>
                        <div className="text">
                          Monitored technical aspects of the front-end delivery
                          for projects.
                        </div>
                      </div>

                      <div className="resume-item content-box">
                        <div className="date">2009 - 2010</div>
                        <div className="name">Senior Developer - ABC Inc.</div>
                        <div className="text">
                          Optimize your website and apps performance using
                          latest technology.
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col col-md">
                    <div className="title">
                      <div className="title_inner">Education</div>
                    </div>

                    <div className="resume-items">
                      <div className="resume-item content-box">
                        <div className="date">2006 - 2008</div>
                        <div className="name">Art University - New York</div>
                        <div className="text">
                          Bachelors Degree in Computer Science ABC Technical
                          Institute, Jefferson, Missouri.
                        </div>
                      </div>

                      <div className="resume-item content-box">
                        <div className="date">2005 - 2006</div>
                        <div className="name">Programming Course - Paris</div>
                        <div className="text">
                          Coursework - Git, WordPress, Javascript, iOS, Android,
                          CSS, and JavaScript.
                        </div>
                      </div>

                      <div className="resume-item content-box">
                        <div className="date">2004 - 2005</div>
                        <div className="name">Web Design Course - London</div>
                        <div className="text">
                          Converted Photoshop layouts to web pages using HTML,
                          CSS, and JavaScript.
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="section skills" id="section-skills">
              <div className="content">
                <div className="title">
                  <div className="title_inner">Design Skills</div>
                </div>

                <div className="skills percent content-box">
                  <ul>
                    <li>
                      <div className="name">Web Design</div>
                      <div className="progress ">
                        <div className="percentage" style="width: 90%;">
                          <span className="percent">90%</span>
                        </div>
                      </div>
                    </li>
                    <li>
                      <div className="name">Illustrations</div>
                      <div className="progress ">
                        <div className="percentage" style="width: 70%;">
                          <span className="percent">70%</span>
                        </div>
                      </div>
                    </li>
                    <li>
                      <div className="name">Photoshop</div>
                      <div className="progress ">
                        <div className="percentage" style="width: 95%;">
                          <span className="percent">95%</span>
                        </div>
                      </div>
                    </li>
                    <li>
                      <div className="name">Graphic Design</div>
                      <div className="progress ">
                        <div className="percentage" style="width: 85%;">
                          <span className="percent">85%</span>
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>

            <div className="section skills" id="section-skills-lang">
              <div className="content">
                <div className="title">
                  <div className="title_inner">Languages Skills</div>
                </div>

                <div className="skills dotted content-box">
                  <ul>
                    <li>
                      <div className="name">English</div>
                      <div className="progress">
                        <div className="percentage" style="width: 90%;">
                          <span className="percent">90%</span>
                        </div>
                      </div>
                    </li>
                    <li>
                      <div className="name">German</div>
                      <div className="progress">
                        <div className="percentage" style="width: 70%;">
                          <span className="percent">70%</span>
                        </div>
                      </div>
                    </li>
                    <li>
                      <div className="name">Italian</div>
                      <div className="progress">
                        <div className="percentage" style="width: 55%;">
                          <span className="percent">55%</span>
                        </div>
                      </div>
                    </li>
                    <li>
                      <div className="name">French</div>
                      <div className="progress">
                        <div className="percentage" style="width: 85%;">
                          <span className="percent">85%</span>
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>

            <div className="section skills" id="section-skills-code">
              <div className="content">
                <div className="title">
                  <div className="title_inner">Coding Skills</div>
                </div>

                <div className="skills circles content-box">
                  <ul>
                    <li>
                      <div className="name">WordPress</div>
                      <div className="progress p90">
                        <div className="percentage">
                          <span className="percent">90%</span>
                        </div>
                        <span>90%</span>
                      </div>
                    </li>
                    <li>
                      <div className="name">PHP / MYSQL</div>
                      <div className="progress p75">
                        <div className="percentage">
                          <span className="percent">75%</span>
                        </div>
                        <span>75%</span>
                      </div>
                    </li>
                    <li>
                      <div className="name">Angular / JavaScript</div>
                      <div className="progress p85">
                        <div className="percentage">
                          <span className="percent">85%</span>
                        </div>
                        <span>85%</span>
                      </div>
                    </li>
                    <li>
                      <div className="name">HTML / CSS</div>
                      <div className="progress p95">
                        <div className="percentage">
                          <span className="percent">95%</span>
                        </div>
                        <span>95%</span>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>

            <div className="section skills" id="section-skills-know">
              <div className="content">
                <div className="title">
                  <div className="title_inner">Knowledge</div>
                </div>

                <div className="skills list content-box">
                  <ul>
                    <li>
                      <div className="name">Website hosting</div>
                    </li>
                    <li>
                      <div className="name">iOS and android apps</div>
                    </li>
                    <li>
                      <div className="name">Create logo design</div>
                    </li>
                    <li>
                      <div className="name">Design for print</div>
                    </li>
                    <li>
                      <div className="name">Modern and mobile-ready</div>
                    </li>
                    <li>
                      <div className="name">Advertising services include</div>
                    </li>
                    <li>
                      <div className="name">Graphics and animations</div>
                    </li>
                    <li>
                      <div className="name">Search engine marketing</div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>

            <div className="section service" id="section-interests">
              <div className="content">
                <div className="title">
                  <div className="title_inner">Interests</div>
                </div>

                <div className="service-items">
                  <div className="service-col">
                    <div className="service-item content-box">
                      <div className="icon">
                        <span className="fas fa-baseball-ball"></span>
                      </div>
                      <div className="name">Basketball</div>
                      <div className="text">
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                      </div>
                    </div>
                  </div>

                  <div className="service-col">
                    <div className="service-item content-box">
                      <div className="icon">
                        <span className="fas fa-campground"></span>
                      </div>
                      <div className="name">Camping</div>
                      <div className="text">
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                      </div>
                    </div>
                  </div>

                  <div className="service-col">
                    <div className="service-item content-box">
                      <div className="icon">
                        <span className="fas fa-chess-knight"></span>
                      </div>
                      <div className="name">Chess</div>
                      <div className="text">
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                      </div>
                    </div>
                  </div>

                  <div className="service-col">
                    <div className="service-item content-box">
                      <div className="icon">
                        <span className="fas fa-headphones"></span>
                      </div>
                      <div className="name">Music</div>
                      <div className="text">
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                      </div>
                    </div>
                  </div>
                </div>

                <div className="clear"></div>
              </div>
            </div>

            <div className="section team" id="section-team">
              <div className="content">
                <div className="title">
                  <div className="title_inner">Our Team</div>
                </div>

                <div className="team-items">
                  <div className="team-col">
                    <div className="team-item content-box">
                      <div className="image">
                        <img src="images/team1.jpg" alt="" />
                      </div>
                      <div className="desc">
                        <div className="name">Alejandro Abeyta</div>
                        <div className="category">Web Developer</div>
                        <div className="soc">
                          <a target="_blank" href="https://www.pinterest.com/">
                            <span className="icon fab fa-pinterest"></span>
                          </a>
                          <a target="_blank" href="https://www.instagram.com/">
                            <span className="icon fab fa-instagram"></span>
                          </a>
                          <a target="_blank" href="https://dribbble.com/">
                            <span className="icon fab fa-dribbble"></span>
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="team-col">
                    <div className="team-item content-box">
                      <div className="image">
                        <img src="images/team2.jpg" alt="" />
                      </div>
                      <div className="desc">
                        <div className="name">Peter Green</div>
                        <div className="category">Back-end Developer</div>
                        <div className="soc">
                          <a target="_blank" href="https://www.pinterest.com/">
                            <span className="icon fab fa-pinterest"></span>
                          </a>
                          <a target="_blank" href="https://www.instagram.com/">
                            <span className="icon fab fa-instagram"></span>
                          </a>
                          <a target="_blank" href="https://dribbble.com/">
                            <span className="icon fab fa-dribbble"></span>
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="section testimonials" id="section-testimonials">
              <div className="content">
                <div className="title">
                  <div className="title_inner">Testimonials</div>
                </div>

                <div className="reviews-carousel">
                  <div className="swiper-container">
                    <div className="swiper-wrapper">
                      <div className="swiper-slide">
                        <div className="reviews-item content-box">
                          <div className="image">
                            {/*<img src="images/rev1.jpg" alt=""> */}
                          </div>
                          <div className="info">
                            <div className="name">Helen Floyd</div>
                            <div className="company">Art Director</div>
                          </div>
                          <div className="text">
                            Lorem ipsum dolor sit amet, consectetur adipiscing
                            elit. Lorem ipsum dolor sit amet, consectetur
                            adipiscing elit.
                          </div>
                        </div>
                      </div>

                      <div className="swiper-slide">
                        <div className="reviews-item content-box">
                          <div className="image">
                            {/* <img src="images/rev1.jpg" alt="">*/}
                          </div>
                          <div className="info">
                            <div className="name">Helen Floyd</div>
                            <div className="company">Art Director</div>
                          </div>
                          <div className="text">
                            Lorem ipsum dolor sit amet, consectetur adipiscing
                            elit. Lorem ipsum dolor sit amet, consectetur
                            adipiscing elit.
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="swiper-nav">
                    <a className="prev swiper-button-prev fas fa-long-arrow-alt-left"></a>
                    <a className="next swiper-button-next fas fa-long-arrow-alt-right"></a>
                  </div>
                </div>
              </div>
            </div>

            <div className="section clients" id="section-clients">
              <div className="content">
                <div className="title">
                  <div className="title_inner">Clients</div>
                </div>

                <div className="content-box">
                  <div className="clients-items">
                    <div className="clients-col">
                      <div className="clients-item">
                        <a target="_blank" href="#">
                          <img src="images/client1.png" alt="" />
                        </a>
                      </div>
                    </div>

                    <div className="clients-col">
                      <div className="clients-item">
                        <a target="_blank" href="#">
                          <img src="images/client3.png" alt="" />
                        </a>
                      </div>
                    </div>

                    <div className="clients-col">
                      <div className="clients-item">
                        <a target="_blank" href="#">
                          <img src="images/client2.png" alt="" />
                        </a>
                      </div>
                    </div>

                    <div className="clients-col">
                      <div className="clients-item">
                        <a target="_blank" href="#">
                          <img src="images/client4.png" alt="" />
                        </a>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="clear"></div>
              </div>
            </div>

            <div className="section custom-text" id="section-custom-text">
              <div className="content">
                <div className="title">
                  <div className="title_inner">Custom Text</div>
                </div>

                <div className="content-box">
                  <div className="single-post-text">
                    <p>
                      Now there is more fashion. There is no so-called trends.
                      Now chase after anything not necessary — nor for
                      fashionable color nor the shape, nor for style. Think
                      about the content that you want to invest in a created
                      object, and only then will form. The thing is your spirit.
                      A spirit unlike forms hard copy.
                    </p>
                    <p>
                      Here choose yourself like that, without any looking back,
                      do your personal, home, small fashion, and all will be
                      well.
                    </p>
                  </div>
                </div>

                <div className="clear"></div>
              </div>
            </div>
          </div>

          {/* Footer 2 */}
          <Footer2></Footer2>

          <div className="lines">
            <div className="line-col"></div>
            <div className="line-col"></div>
            <div className="line-col"></div>
            <div className="line-col"></div>
            <div className="line-col"></div>
          </div>
        </div>
      </div>
    );
  }
}

export default cv;
