import { Component } from "react";
import { Link, NavLink } from "react-router-dom";

class header extends Component {
  render() {
    return (
      <header className="header">
        <div className="head-top">
          <a href="#" className="menu-btn">
            <span></span>
          </a>

          <div className="logo hover-masks-logo">
            <a href="#">
              <span className="mask-lnk">
                Wilmer <strong>E. Ordoñez</strong>
              </span>
              <span className="mask-lnk mask-lnk-hover">
                Download <strong>CV</strong>
              </span>
            </a>
          </div>

          <div className="top-menu hover-masks">
            <div className="top-menu-nav">
              <div className="menu-topmenu-container">
                <ul className="menu">
                  <li className="menu-item menu-item-has-children current-menu-item">
                    {/*<a href="index.html">Inicio</a>*/}
                    <Link to={"/home"} reloadDocument={true}>
                      Inicio
                    </Link>

                    <ul class="sub-menu">
                      <li class="menu-item">
                        {/*<a href="index.html">Inicio</a>*/}
                        <Link to={"/home"} reloadDocument={true}>
                          Inicio
                        </Link>
                      </li>
                    </ul>
                  </li>
                  <li className="menu-item menu-item-has-children">
                    <NavLink to="/cv">CV</NavLink>
                    <ul class="sub-menu">
                      <li class="menu-item">
                        <NavLink to="/cv">CV</NavLink>
                      </li>
                      <li class="menu-item">
                        <a href="resume_image.html">Carta de Presentación</a>
                      </li>
                      <li class="menu-item">
                        <a href="resume_image.html">Experiencia Laboral</a>
                      </li>
                      <li class="menu-item">
                        <a href="resume_image.html">Estudios</a>
                      </li>
                      <li class="menu-item">
                        <a href="resume_image.html">Certificaciones</a>
                      </li>
                      <li class="menu-item">
                        <a href="resume_image.html">Idiomas</a>
                      </li>
                      <li class="menu-item">
                        <a href="resume_image.html">Tecnologías</a>
                      </li>
                      <li class="menu-item">
                        <a href="resume_image.html">Redes Sociales</a>
                      </li>
                    </ul>
                  </li>
                  <li className="menu-item menu-item-has-children">
                    <a href="works.html">Productos</a>
                    <ul class="sub-menu">
                      <li class="menu-item">
                        <a href="works.html">Paginas Web</a>
                      </li>
                      <li class="menu-item">
                        <a href="works_creative.html">Punto de Venta</a>
                      </li>
                      <li class="menu-item">
                        <a href="work_single.html">Servicos Generales</a>
                      </li>
                      <li class="menu-item">
                        <a href="work_single_image.html">Taxi App</a>
                      </li>
                      <li class="menu-item">
                        <a href="work_single_creative.html">Biblioteca</a>
                      </li>
                    </ul>
                  </li>
                  <li className="menu-item menu-item-has-children">
                    <Link to={"/contactar"} reloadDocument={true}>
                      Contactar
                    </Link>
                    <ul class="sub-menu">
                      <li class="menu-item">
                        <Link to={"/contactar"} reloadDocument={true}>
                          Contactar
                        </Link>
                      </li>
                      <li class="menu-item">
                        <a href="works_creative.html">Contact</a>
                      </li>
                      <li class="menu-item">
                        <a href="work_single.html">Contacter</a>
                      </li>
                      <li class="menu-item">
                        <a href="work_single_image.html">Kontakt</a>
                      </li>
                      <li class="menu-item">
                        <a href="work_single_creative.html">接觸</a>
                      </li>
                      <li class="menu-item">
                        <a href="work_single_creative.html">接触</a>
                      </li>
                      <li class="menu-item">
                        <a href="work_single_creative.html">Контакт</a>
                      </li>
                    </ul>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </header>
    );
  }
}

export default header;
