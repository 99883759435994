import { Component } from "react";
import Footerinfo from "./footerinfo";
import Footerredessociales from "./footerredessociales";

class footer2 extends Component {
  render() {
    return (
      <footer ClassName="footer">
        <Footerinfo />
        <Footerredessociales />
        <div ClassName="clear"></div>
      </footer>
    );
  }
}

export default footer2;
