import { Component } from "react";
import Header from "../Modulos/header";
import Footer from "../Modulos/footer";

class contact extends Component {
  render() {
    return (
      <>
        {/* Preloader */}
        <div className="preloader">
          <div className="centrize full-width">
            <div className="vertical-center">
              <div className="spinner">
                <div className="double-bounce1"></div>
                <div className="double-bounce2"></div>
              </div>
            </div>
          </div>
        </div>

        {/* Container */}
        <div className="container">
          {/* Cursor */}
          <div className="cursor-follower"></div>

          {/* Header */}
          <Header />

          {/* Wrapper */}
          <div className="wrapper">
            {/* Section Started */}
            <div className="section started section-title" id="section-started">
              {/* background */}
              <div className="video-bg">
                <div className="video-bg-mask"></div>
                <div className="video-bg-texture" id="grained_container"></div>
              </div>

              {/* started content */}
              <div className="centrize full-width">
                <div className="vertical-center">
                  <div className="started-content">
                    <h1 className="h-title">Contactar</h1>
                    <div className="h-subtitles">
                      <div className="h-subtitle typing-bread">
                        <p className="breadcrumbs">
                          <a href="index.html">Incio</a> / Contactar
                        </p>
                      </div>
                      <span className="typed-bread"></span>
                    </div>
                  </div>
                </div>
              </div>

              {/* mosue button */}
              <a href="#" className="mouse_btn" style={{ display: "none" }}>
                <span className="icon fas fa-chevron-down"></span>
              </a>
            </div>

            {/* Section Contacts Info */}
            <div className="section contacts" id="section-contacts-info">
              <div className="content">
                {/* title */}
                <div className="title">
                  <div className="title_inner">Contacts Info</div>
                </div>

                {/* contacts items */}
                <div className="service-items">
                  <div className="service-col">
                    <div className="service-item content-box">
                      <div className="icon">
                        <span className="fas fa-phone"></span>
                      </div>
                      <div className="name">Phone</div>
                      <div className="text">+ (231) 456 67 89</div>
                    </div>
                  </div>

                  <div className="service-col">
                    <div className="service-item content-box">
                      <div className="icon">
                        <span className="fas fa-envelope"></span>
                      </div>
                      <div className="name">Email</div>
                      <div className="text">
                        <a href="mailto:steve-pearson@gmail.com">
                          steve-pearson@gmail.com
                        </a>
                      </div>
                    </div>
                  </div>

                  <div className="service-col">
                    <div className="service-item content-box">
                      <div className="icon">
                        <span className="fas fa-map-marker-alt"></span>
                      </div>
                      <div className="name">Address</div>
                      <div className="text">2621 W Pico Blvd, Los Angeles</div>
                    </div>
                  </div>

                  <div className="service-col">
                    <div className="service-item content-box">
                      <div className="icon">
                        <span className="fas fa-user-tie"></span>
                      </div>
                      <div className="name">Freelance Available</div>
                      <div className="text">
                        I am available for Freelance hire
                      </div>
                    </div>
                  </div>
                </div>

                <div className="clear"></div>
              </div>
            </div>

            {/* Section Contacts Form */}
            <div className="section contacts" id="section-contacts">
              <div className="content">
                {/* title */}
                <div className="title">
                  <div className="title_inner">Contacts Form</div>
                </div>

                {/* form */}
                <div className="contact_form content-box">
                  <form id="cform" method="post">
                    <div className="group-val">
                      <input type="text" name="name" placeholder="Name" />
                    </div>
                    <div className="group-val">
                      <input type="email" name="email" placeholder="Email" />
                    </div>
                    <div className="group-val ct-gr">
                      <textarea name="message" placeholder="Message"></textarea>
                    </div>
                    <div className="group-bts">
                      <button type="submit" className="btn hover-animated">
                        <span className="circle"></span>
                        <span className="lnk">Send Message</span>
                      </button>
                    </div>
                  </form>
                  <div className="alert-success">
                    <p>Thanks, your message is sent successfully.</p>
                  </div>
                </div>
              </div>
              <div className="clear"></div>
            </div>
          </div>

          {/* Footer */}
          <Footer />

          {/* Lines */}
          <div className="lines">
            <div className="line-col"></div>
            <div className="line-col"></div>
            <div className="line-col"></div>
            <div className="line-col"></div>
            <div className="line-col"></div>
          </div>
        </div>
      </>
    );
  }
}

export default contact;
