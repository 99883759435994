import { Component } from "react";
import Footerredessociales from "./footerredessociales";
import Footerinfo from "./footerinfo";

class footer extends Component {
  render() {
    return (
      <footer className="footer">
        <Footerinfo />
        <Footerredessociales />
        <div className="clear"></div>
      </footer>
    );
  }
}

export default footer;
