import { Component } from "react";
import Header from "../Modulos/header";
import Footer from "../Modulos/footer";

class home extends Component {
  render() {
    return (
      <div className="home">
        <div className="preloader">
          <div className="centrize full-width">
            <div className="vertical-center">
              <div className="spinner">
                <div className="double-bounce1"></div>
                <div className="double-bounce2"></div>
              </div>
            </div>
          </div>
        </div>

        <div className="container">
          <div className="cursor-follower"></div>

          {/* HEADER COMPONENT HERE */}
          <Header></Header>

          <div className="wrapper">
            <div className="section started personal" id="section-started">
              <div className="video-bg">
                <div className="video-bg-mask"></div>
                <div
                  className="video-bg-texture"
                  id="grained_container"
                  style={{ position: "relative", overflow: "hidden" }}
                ></div>
              </div>

              <div className="centrize full-width">
                <div className="vertical-center">
                  <div className="started-content">
                    <div className="logo logoBackground"></div>
                    <h1 className="h-title">
                      Hola, Yo soy <strong>Wilmer E. Ordoñez, </strong>
                      <br />
                      <strong>Desarrollador</strong> .net, PHP, Java, <br />
                      <strong>Front/Back</strong> Developer <br />
                      Localizado en <strong>Lima-Perú.</strong>
                      <br />
                    </h1>
                    <div className="h-subtitle typing-subtitle">
                      <p>
                        .net <strong>Developer</strong>
                      </p>
                      <p>
                        Net <strong>Developer</strong>
                      </p>
                      <p>
                        .net <strong>Entity FrameWork</strong>
                      </p>
                      <p>
                        RestFULL <strong>Services</strong>
                      </p>
                      <p>
                        PHP <strong>Developer</strong>
                      </p>
                      <p>
                        Java <strong>Developer</strong>
                      </p>
                      <p>
                        Android <strong>Developer</strong>
                      </p>
                      <p>
                        Azure <strong>PowerApps</strong>
                      </p>
                      <p>
                        Amazon <strong>Services</strong>
                      </p>
                      <p>
                        Microsoft <strong>Services</strong>
                      </p>
                      <p>
                        MSSQL <strong>Management</strong>
                      </p>
                      <p>
                        MYSQL <strong>Management</strong>
                      </p>
                      <p>
                        MariaDB <strong>Management</strong>
                      </p>
                      <p>
                        SQLite <strong>Management</strong>
                      </p>
                    </div>
                    <span className="typed-subtitle"></span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* FOOTER COMPONENT HERE */}
          <Footer></Footer>

          <div className="lines">
            <div className="line-col"></div>
            <div className="line-col"></div>
            <div className="line-col"></div>
            <div className="line-col"></div>
            <div className="line-col"></div>
          </div>
        </div>
      </div>
    );
  }
}

export default home;
