import { Component } from "react";

class footerredessociales extends Component {
  render() {
    return (
      <div className="soc-box">
        <div className="follow-label">Sigueme</div>
        <div className="soc">
          <a target="_blank" href="https://www.pinterest.com/">
            <span className="icon fab fa-pinterest"></span>
          </a>
          <a target="_blank" href="https://www.instagram.com/bartzarwhite/">
            <span className="icon fab fa-instagram"></span>
          </a>
          <a
            target="_blank"
            href="https://www.facebook.com/wilmer.ordonezegoavil"
          >
            <span className="icon fab fa-facebook"></span>
          </a>
          <a target="_blank" href="https://www.youtube.com/">
            <span className="icon fab fa-youtube"></span>
          </a>
          <a
            target="_blank"
            href="https://www.linkedin.com/in/wilmer-ordo%C3%B1ez-egoavil-92b156b4/"
          >
            <span className="icon fab fa-linkedin"></span>
          </a>
        </div>
      </div>
    );
  }
}

export default footerredessociales;
