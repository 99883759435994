import { Component } from "react";

class footerinfo extends Component {
  render() {
    return (
      <div className="copy">
        <p>E: wilmer.ordonez.egoavil@gmail.com</p>
        <p>T: +51 985 034 001</p>
      </div>
    );
  }
}

export default footerinfo;
